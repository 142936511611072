import { Box } from "@mui/material";
import "./App.css";
import { About } from "./components/About";
import { Main } from "./components/Main";
import { Topbar } from "./components/Topbar";
import { Footer } from "./components/Footer";

function App() {
  return (
    <Box>
      <Topbar />
      <Main />
      <About />
      <Footer />
    </Box>
  );
}

export default App;
