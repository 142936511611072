import { Facebook, Hotel, Phone } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";

export const Footer = () => {
  return (
    <Box
      sx={{
        minHeight: "30vh",
        backgroundColor: "#000000",
        width: "100%",
        borderTopLeftRadius: 100,
        borderTopRightRadius: 100,
        color: "#FFC000",
      }}
    >
      <Grid container sx={{ paddingLeft: 10, paddingRight: 10 }}>
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Stack direction="row">
            <Typography variant="body1" sx={{ color: "white" }}>
              Twin Elephant &nbsp;
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFC000" }}>
              Properties
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} sx={{ pt: 2 }}>
          <List
            subheader={
              <ListSubheader
                component="div"
                sx={{ color: "white", backgroundColor: "#000000" }}
              >
                Connect with us
              </ListSubheader>
            }
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Facebook sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Facebook" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Hotel sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Airbnb" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Phone sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="416-725-8198" />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            overflow: "hidden",
            mb: 2,
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2852.83242165929!2d-79.61678892330363!3d44.35449360679131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882abbe3ac5141b5%3A0x7c81c1bec0c9cb65!2sTwin%20Elephant%20properties!5e0!3m2!1sen!2sca!4v1710981132709!5m2!1sen!2sca"
            width="100%"
            height="100%"
            style={{ border: "none" }}
            loading="lazy"
            title="Google Map Embed"
          ></iframe>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            pb: 10,
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="column">
            <Typography variant="h6">75 Coronation Parkway</Typography>
            <Typography variant="h6">Barrie, ON, L4M0E2</Typography>
            <Typography variant="body1" sx={{ pt: 2 }}>
              © 2024 Twin Elephant Properties. All rights reserved.
            </Typography>
            <Typography variant="body1">
              Reproduction without explicit permission is prohibited.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
