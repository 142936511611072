import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Home, RocketLaunchOutlined } from "@mui/icons-material";

import hall1 from "../components/assets/images/hall1.png";
import hall2 from "../components/assets/images/hall2.png";
import luke1 from "./assets/images/luke1.png";
import kitchen1 from "./assets/images/kitchen1.png";
import innisfil from "./assets/images/imageGallery/innisfil1.png";

export const Main = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container sx={{ my: 6 }} spacing={1}>
      <Grid item xs={12}>
        <Card>
          <div style={{ position: "relative" }}>
            <CardMedia
              style={{ height: isSmallScreen ? "100vh" : "" }}
              component="img"
              image={innisfil}
              title="Luke"
              alt="LukeisaRockstar"
              sx={{ opacity: 0.7 }}
            />
            <div
              style={{
                position: "absolute",
                top: "80px",
                marginLeft: "40px",
                maxWidth: "700px",
              }}
            >
              <Stack spacing={4}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={2}
                >
                  <RocketLaunchOutlined />

                  <Typography variant="h4" color="textPrimary">
                    <b>
                      Our mission is to provide you with the best shared rental
                    </b>
                  </Typography>
                </Stack>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  sx={{ fontFamily: "Josefin Sans" }}
                >
                  <b>
                    Having more than 20+ available options in Barrie, Toronto,
                    Innisfil and constantly adding more rental properties in our
                    basket.
                  </b>
                </Typography>
              </Stack>
            </div>
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ my: "50px", mt: "50px" }}>
        <Stack spacing={3} sx={{ mx: 10 }}>
          <Typography variant="h4" sx={{ color: "#FFC000" }}>
            <Home sx={{ mr: 2, color: "Black" }} />
            Come Live with us
          </Typography>
          <Typography variant="h4">
            Embark on a journey through our diverse array of properties,
            meticulously selected to cater to your every need and desire. From
            cozy apartments to spacious homes, explore our curated listings
            across various locations, each promising a unique blend of comfort,
            convenience, and modern living. Find your dream property today.
          </Typography>
        </Stack>
      </Grid>
      {/* <Box sx={{ display: "flex", mx: "50px", my: "50px" }}> */}
      <Grid item xs={12} sx={{ mr: 1 }}>
        <Stack direction={isSmallScreen ? "column" : "row"} spacing={2}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                height="640"
                image={hall1}
                alt="barrie"
              />
              <CardContent>
                <Typography gutterBottom variant="h2" component="div">
                  Barrie
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Explore a diverse selection of properties in Barrie, each
                  offering unique charm and modern amenities for your ideal
                  living space.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                height="640"
                image={hall2}
                alt="innisfil"
              />
              <CardContent>
                <Typography gutterBottom variant="h2" component="div">
                  Innisfil
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Discover a range of properties in scenic Innisfil, providing a
                  perfect blend of tranquility and modern living for every
                  lifestyle.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                height="640"
                image={kitchen1}
                alt="northYork"
              />
              <CardContent>
                <Typography gutterBottom variant="h2" component="div">
                  North York
                </Typography>
                <Typography variant="h5" color="text.secondary">
                  Find your perfect home among a variety of properties in
                  vibrant North York, offering diversity and urban charm.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mr: 1 }}></Grid>
      <Grid item xs={12}></Grid>
      {/* </Box> */}
    </Grid>
  );
};
