import { Box, AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export const Topbar = () => {
  return (
    <Box>
      {/* <AppBar position="fixed" sx={{ backgroundColor: "White" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
          }}
        >
          <Typography variant="h6">
            We are 100% Canadian 🇨🇦 we are proud of it.
          </Typography>
        </Toolbar>
      </AppBar> */}
      <AppBar position="fixed" sx={{ backgroundColor: "black" }}>
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: "white" }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" sx={{ color: "white" }}>
            Twin Elephant &nbsp;
          </Typography>
          <Typography variant="h6" sx={{ color: "#FFC000" }}>
            Properties
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
