import { StarBorder } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from "@mui/material";

import leisure1 from "./assets/images/imageGallery/leisure1.png";
import lobby2 from "./assets/images/imageGallery/lobby2.png";
import maria2 from "./assets/images/imageGallery/maria2.png";
import main1 from "./assets/images/imageGallery/main1.png";
import room7 from "./assets/images/imageGallery/room7.png";
import room8 from "./assets/images/imageGallery/room8.png";
import room11 from "./assets/images/imageGallery/room11.png";
import innisfil1 from "./assets/images/imageGallery/innisfil1.png";
import room1 from "./assets/images/imageGallery/room1.png";
import room2 from "./assets/images/imageGallery/room2.png";

function srcset(
  image: string,
  width: number,
  height: number,
  rows = 1,
  cols = 1
) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const About = () => {
  const itemData = [
    {
      img: leisure1,
      title: "Party",
    },
    {
      img: lobby2,
      title: "Christmas 2023",
    },
    {
      img: main1,
      title: "Coffee",
    },
    {
      img: maria2,
      title: "Hats",
    },
    {
      img: room8,
      title: "Honey",
      featured: true,
    },
    {
      img: room2,
      title: "Basketball",
    },
    {
      img: innisfil1,
      title: "Fern",
    },
    {
      img: room11,
      title: "Mushrooms",
    },
    {
      img: room1,
      title: "Tomato basil",
    },
    {
      img: room7,
      title: "Sea star",
    },
    {
      img: room8,
      title: "Bike",
    },
  ];
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mx: "50px" }}>
      <Stack direction="column">
        <Typography variant="h4" sx={{ color: "#FFC000" }}>
          Explore a visual journey through our dynamic <b>photo gallery</b>,
          where each image tells a unique story, inviting you to discover and
          connect.
        </Typography>
        <ImageList
          sx={{
            borderRadius: "10px",
            my: "50px",
          }}
          gap={2}
        >
          {itemData.map((item) => {
            const cols = item.featured ? 2 : 1;
            const rows = item.featured ? 2 : 1;

            return (
              <ImageListItem key={item.img} cols={cols} rows={rows}>
                <img
                  {...srcset(item.img, 250, 200, rows, cols)}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  title={item.title}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`star ${item.title}`}
                    >
                      <StarBorder />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Stack>
    </Box>
  );
};
